import { NextSeo } from 'next-seo';
import { getFullUrl } from '~/helpers/url';

const Seo = ({ title = '', description = '', path = '/' }) => {
  description = `${description}`;
  title = `${title}`;

  const suffix = ' | repman.io | Private PHP Package Repository Manager';
  const pageTitle = `${title.substr(0, 50)}${suffix}`;
  const pageDescription = description.substr(0, 150);
  const canonical = path === '/' ? getFullUrl(path) : `${getFullUrl(path)}/`;

  return (
    <>
      <NextSeo
        title={pageTitle}
        description={pageDescription}
        canonical={canonical}
        twitter={{
          cardType: 'summary_large_image',
          site: '@repman_io'
        }}
        openGraph={{
          url: getFullUrl(path),
          title: pageTitle,
          description: pageDescription,
          images: [{
            url: getFullUrl('/og-image.png')
          }],
          site_name: 'Repman: Private PHP Package Repository Manager',
          type: 'article',
          locale: 'en_US'
        }}
      />
    </>
  );
};

export default Seo;
