import NavBar from '~/components/navBar';
import Footer from '~/components/footer';

const DefaultLayout = ({ children }) => {
  return (
    <>
      <NavBar />
      {children}
      <Footer />
    </>
  );
};

export default DefaultLayout;
