import config from 'config';
import urlJoin from 'url-join';

export const getFullUrl = (path) => {
  return prepareUrl(config.rootUrl, path);
};

export const getDocsUrl = (path) => {
  return prepareUrl(config.docsUrl, path);
};

export const getAppUrl = (path) => {
  return prepareUrl(config.appUrl, path);
};

export const getAppProxyUrl = () => {
  return 'https://repo.repman.io';
};

export const getRegisterUrl = (source) => {
  if (source) {
    return getAppUrl(`register/${source}`);
  }

  return getAppUrl('register');
};

export const getLoginUrl = () => {
  return getAppUrl('login');
};

export const getBuddyMarketingUrl = (path) => {
  const params = [
    '?utm_campaign=repman_spring',
    'utm_medium=link',
    'utm_source=repman',
  ];

  return prepareUrl('https://buddy.works', path, params.join('&'));
};

export const getGitHubUrl = (path) => {
  return prepareUrl('https://github.com/repman-io/repman', path);
};

export const getTwitterUrl = () => {
  return prepareUrl('https://twitter.com/repman_io');
};

export const removeTrailingSlashes = (path) => {
  if (path && path.endsWith('/')) {
    return path.replace(/(.+)\/$/, '$1');
  }

  return path || '';
};

const prepareUrl = (...parts) => {
  return removeTrailingSlashes(urlJoin(...parts.filter(Boolean)));
};
