import { useState } from 'react';
import ActiveLink from '~/components/activeLink';
import logo from '~/assets/img/logo-repman.svg';
import { getRegisterUrl, getLoginUrl, getDocsUrl } from '~/helpers/url';
import CtaClick from '~/components/ctaClick';

export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="nav-main">
      <div className={`nav-container ${isMenuOpen ? 'open' : ''}`}>
        <div className="brand-container">
          <ActiveLink href="/">
            <a className="brand">
              <img src={logo} alt="repman logo" className="brand__image" />
            </a>
          </ActiveLink>
        </div>
        <nav className="nav">
          <CtaClick cta={['docs-navigation-bar']}>
            <a href={getDocsUrl()} className="nav-item">
              Docs
            </a>
          </CtaClick>
          <CtaClick cta={['proxy-navigation-bar']}>
            <ActiveLink href="/proxy">
              <a className="nav-item">
                PROXY
              </a>
            </ActiveLink>
          </CtaClick>
          <CtaClick cta={['private-packages-navigation-bar']}>
            <ActiveLink href="/">
              <a className="nav-item">
                Private Packages
              </a>
            </ActiveLink>
          </CtaClick>
          <CtaClick cta={['self-hosted-navigation-bar']}>
            <ActiveLink href="/self-hosted">
              <a className="nav-item">
                Self-hosted
              </a>
            </ActiveLink>
          </CtaClick>
          <a href={getLoginUrl()} className="mobile nav-item">Sign in</a>
          <CtaClick cta={['sign-up-navigation-bar']}>
            <a href={getRegisterUrl()} className="mobile nav-item">Sign up</a>
          </CtaClick>
        </nav>
        <div className="nav_login">
          <a href={getLoginUrl()} className="nav_login__link">Sign in</a>
          <CtaClick cta={['sign-up-navigation-bar']}>
            <a href={getRegisterUrl()} className="btn btn-primary nav_login__link">Sign up</a>
          </CtaClick>
          <button
            type="button"
            className="toggler"
            aria-label="Toggle menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span className="toggler__icon" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}
