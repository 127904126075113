import React from 'react';
import { event } from '~/lib/gtag';

const CtaClick = ({ children, cta = [] }) => {
  return (
    <>
      {React.Children.map(children, (child) => (
        React.cloneElement(child, {
          onClick: handleClick(child.props.onClick)
        })
      ))}
    </>
  );

  function handleClick(originalOnClick) {
    return (...opts) => {
      sendCtaEvents(cta);
      return originalOnClick && originalOnClick(...opts);
    };
  }

  function sendCtaEvents(cta) {
    cta
      .filter(Boolean)
      .forEach((ctaId) => sendCta(ctaId));
  }

  function sendCta(ctaId) {
    event({
      category: 'CTA',
      action: ctaId,
      label: window.location.pathname
    });
  }
};

export default CtaClick;
