import { withRouter } from 'next/router';
import Link from 'next/link';
import { getBuddyMarketingUrl, getGitHubUrl, getTwitterUrl } from '~/helpers/url';
import heart from '~/assets/img/heart.svg';
import twitterIcon from '~/assets/img/twitter-icon.svg';

const MarketingLink = ({ isHome }) => {
  return isHome
    ? <a href={getBuddyMarketingUrl()} className="link">Buddy</a>
    : <a href={getBuddyMarketingUrl()} className="link" rel="nofollow">Buddy</a>;
};

const Footer = ({ router }) => {
  const isHome = router.pathname === '/';

  return (
    <footer className="footer">
      <div className="container">
        <div className="text text--light">
          <div className="item">
            In case of questions and suggestions, please{' '}
            <a href={getGitHubUrl('issues')}>open an issue</a>
          </div>
          <div className="item">|</div>
          <div className="item">
            <Link href="/terms-of-service">
              <a>Terms</a>
            </Link>
          </div>
          <div className="item">|</div>
          <div className="item">
            <Link href="/privacy-policy">
              <a>Privacy</a>
            </Link>
          </div>
          <div className="item">|</div>
          <div className="item">
            <a href={getTwitterUrl()}>
              <img className="footer__pic" alt="Repman Twitter" src={twitterIcon} />
            </a>
          </div>
        </div>
        <div className="text footer__copy text--light">
          made with <img src={heart} alt="heart" className="footer__pic" /> by{' '}
          <MarketingLink isHome={isHome} />
        </div>
      </div>
    </footer>
  );
};

export default withRouter(Footer);
